<template>
	<ion-page>
		<ion-header :translucent="true">
			<ion-toolbar color="ari" class="ion-text-center">
				<ion-title>Konsultasi Dimulai</ion-title>
			</ion-toolbar>
			<ion-progress-bar
				v-if="store.state.isLoading"
				type="indeterminate"
			></ion-progress-bar>
		</ion-header>
		<ion-content :fullscreen="true" slot="fixed">
			<ion-list class="conversation" v-if="store.state.ticket.start != null">
				<ion-item
					v-for="(d, i) in store.state.ticket.start"
					:key="i"
					@click="goToChat(d.TICKET_NO)"
				>
					<!-- <ion-icon :icon="chatboxOutline" class="personIcon"></ion-icon> -->
					<!-- new code -->
					<img
						src="@/assets/relidoc/konsultasi.svg"
						alt=""
						class="icon-width-2"
					/>
					<ion-label
						:class="[
							notYetReadChat.includes(d.TICKET_NO) ? 'notread' : 'listchat',
						]"
					>
						<!-- <span class="newTab" @click="goToChatNewTab(d.TICKET_NO)"
							>New Tab</span
						> -->
						<span class="blue-ari">{{ formatDate(d.TICKET_START_DATE) }}</span
						><br />
						<span>Nomor Tiket : {{ d.TICKET_NO }}</span
						><br />
						<span
							>Pasien : <b>{{ d.MEMBER_NAME }}</b></span
						><br />
						<span>Indikasi : {{ d.TICKET_INDICATION }}</span
						><br />
						<span>Status : {{ d.TICKET_STATUS_NAME.toLowerCase() }}</span>
					</ion-label>
				</ion-item>
			</ion-list>
			<div v-if="store.state.ticket.start == null">
				<!-- <div>
					<ion-icon :icon="alertCircleOutline"></ion-icon><br />
					<span>Belum ada konsultasi dimulai.</span>
				</div> -->
				<div class="flex">
					<div class="no-content">
						<!-- <ion-icon :icon="alertCircleOutline"></ion-icon><br />
						<span>Belum ada konsultasi dimulai.</span> -->

						<!-- newcode -->
						<div class="icon-wrap">
							<img
								src="@/assets/relidoc/informasi.svg"
								alt=""
								class="icon-width"
							/>
							<span>Tidak ada permintaan konsultasi</span>
						</div>
					</div>
				</div>
			</div>
			<!-- <pre>
				{{ store.state.ticket }}
			</pre
			> -->
		</ion-content>
		<bottom-tab-bar></bottom-tab-bar>
	</ion-page>
</template>

<script>
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonList,
	IonItem,
	// IonIcon,
	IonLabel,
	IonProgressBar,
	onIonViewWillEnter,
	onIonViewWillLeave,
} from "@ionic/vue";
import { formatDate } from "@/store/methods/utils/utils";
import db from "@/fb";
import { chatboxOutline, alertCircleOutline } from "ionicons/icons";
import { computed, inject, ref } from "vue";
import { useRouter } from "vue-router";
import BottomTabBar from "@/components/BottomTabBar.vue";

export default {
	name: "Conversation",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		IonTitle,
		IonToolbar,
		IonList,
		IonItem,
		// IonIcon,
		IonLabel,
		IonProgressBar,
		BottomTabBar,
	},
	setup() {
		const store = inject("store");
		const router = useRouter();
		var notYetReadChat = ref([]);
		var unsubscribe = null;
		const doctorId = computed(() => localStorage.getItem("DoctorId"));
		//let tickets = db.collection("doctors").doc(doctorId.value);
		const doctors = db.collection("doctors").doc(doctorId.value);

		function setRealtime() {
			//console.log("set realtime");
			unsubscribe = doctors.onSnapshot(async (docSnapshot) => {
				if (docSnapshot.data() != null) {
					//console.log("snapshot", docSnapshot.data());
					//console.log("isexist", docSnapshot.exists);
					//get new ticket
					store.ticket.listStartConsultation();
					//set list active chat css class
					//let doc = await doctors.get();
					if (docSnapshot.exists) {
						// let data = doc.data()
						let data = docSnapshot.data();
						//console.log("get", data);
						let arr = [];
						for (const [key, value] of Object.entries(data.status)) {
							if (!value) {
								arr.push(key);
							}
						}
						notYetReadChat.value = arr;
					}
				}
			});
		}
		function goToChat(ticketNo) {
			router.replace(`/ticket-onprocess-detail/${ticketNo}`);
		}
		function goToChatNewTab(ticketNo) {
			let url = `/ticket-onprocess-detail/${ticketNo}`;
			window.open(url, "_blank");
			setTimeout(() => {
				router.replace("/ticket-onprocess");
			}, 1000);
		}
		onIonViewWillEnter(async () => {
			setRealtime();
			// store.ticket.listStartConsultation();
		});
		onIonViewWillLeave(() => {
			unsubscribe();
		});
		return {
			store,
			chatboxOutline,
			alertCircleOutline,
			formatDate,
			notYetReadChat,
			goToChat,
			goToChatNewTab,
		};
	},
	data() {
		return {
			notread: "notread",
			listchat: "listchat",
		};
	},
};
</script>

<style scoped>
.newTab {
	color: var(--ion-color-ari);
	text-decoration: underline;
	position: relative;
	float: right;
	margin-right: 8px;
	margin-top: 3px;
}
.icon-wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.icon-width {
	width: 44px;
	margin-bottom: 10px;
}
.icon-width-2 {
	width: 40px;
	margin-right: 15px;
	margin-left: 5px;
}
ion-item {
	cursor: pointer;
}
.personIcon {
	color: var(--ion-color-ari);
	margin-right: 5px;
	margin-left: 0px;
	font-size: 50px;
}
.listchat {
	color: #464947;
	padding: 0;
}
.notread {
	color: #464947;
	background: rgb(2, 0, 36);
	background: linear-gradient(
		90deg,
		rgba(2, 0, 36, 1) 0%,
		rgba(255, 255, 255, 1) 0%,
		rgb(255, 124, 124) 100%
	);
	padding-bottom: 4px;
	padding-top: 4px;
}
.conversation {
	padding: 0;
}
.no-content {
	text-align: center;
	color: rgb(179, 179, 179);
}
.no-content > div > ion-icon {
	font-size: 50px;
	color: rgb(179, 179, 179);
}
.flex {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 79vh;
	overflow: auto;
}
.no-content {
	justify-content: center;
	text-align: center;
	color: rgb(179, 179, 179);
}
.no-content > ion-icon {
	font-size: 50px;
	color: rgb(179, 179, 179);
}
/* .isread {
	background-color: #fff6de !important;
} */
</style>
